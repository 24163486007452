<template>
  <information-app-modal
    v-model="model"
    button-title="Continue"
    @close="closeModal"
    :icon="ICON_TYPE.WARNING_YELLOW"
  >
    <h1 class="title">{{ $t('PlaidProductNotSupportedError.SomethingWentWrongTitle').value }}</h1>

    <p>
      {{ $t('PlaidProductNotSupportedError.UnableToVerifyBankAccountText').value }}
    </p>

    <template #footer>
      <AppButton @click="onHelpCenterClick">
        {{ $t('ContactUsModal.GetInTouchButton').value }}
      </AppButton>
      <AppButton theme="secondary" @click="close">
        {{ $t('ContactUsModal.CloseButton').value }}
      </AppButton>
    </template>
  </information-app-modal>
</template>

<script>


import { useRouter } from '@galileo/composables/useRouter'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'

import { useVModel, AppButton } from '@oen.web.vue2/ui'
import { ref } from '@vue/composition-api'

import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'



import { useAppStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'PlaidProductNotSupportedError',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const router = useRouter()
    const appStore = useAppStore()

    const closeModal = () => {
      model.value = false
    }

    const onHelpCenterClick = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.CONTACT_DETAILS,
        article: HelpDesk[HelpDeskProperties.CONTACT_DETAILS],
      })
    }

    const close = () => {
      router.go(-1)
    }

    return {
      model,
      $t,
      closeModal,
      ICON_TYPE,
      onHelpCenterClick,
      close,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}
</style>
